import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGear, faTrash, faCopy, faMobileButton, faToggleOn, faToggleOff, faPlusSquare, faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { faWindows, faApple, faAndroid } from "@fortawesome/free-brands-svg-icons"
import { getDomainFromURL } from "../functions"
import { useDispatch, useSelector } from "react-redux"
import { updateAddCloackPopup } from "../redux/statSlice"

import { t } from "i18next"

export const Cloacks = () => {
    const [cloacksData, setCloacksData] = useState([]);

    const dispatch = useDispatch()

    const addCloackPopup = useSelector((state) => state.stat.addcloackpopup)

    // Функция для удаления элемента
    const removeCloack = (event) => {
        const confirmed = window.confirm('Are you sure to delete this cloack?');
        if (confirmed) {
            const uuid = event.target.dataset.uuid;
            try {
                const stateSend = {
                    action: 'deletecloack',
                    uuid: uuid
                };
                fetch('https://spaceshipbridge.com/ud/api.php', {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(stateSend)
                })
                .then(response => response.json())
                .then(json => {
                    if (json.deleted) {
                        setCloacksData(prevData => prevData.filter(cloack => cloack.uuid !== uuid));
                    }
                });
            } catch (error) {
                console.error(error);
            }
        }
    };

    // Функция для копирования элемента
    const copyCloack = (event) => {
        const uuid = event.target.dataset.uuid;
        try {
            const stateSend = {
                action: 'copycloack',
                uuid: uuid
            };
            fetch('https://spaceshipbridge.com/ud/api.php', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(stateSend)
            })
            .then(response => response.json())
            .then(json => {
                if (json.data?.length > 0) {
                    setCloacksData([]);
                    try {
                        const searchParams = new URLSearchParams({
                            action: 'getcloacks',
                            id: localStorage.getItem('user.id')
                        });
                        fetch(`https://spaceshipbridge.com/ud/api.php?${searchParams.toString()}`)
                        .then(response => response.json())
                        .then(json => {
                            json.data && json.data.forEach(e => setCloacksData(prevState => ([...prevState, e])));
                        });
                    } catch (error) {
                        console.error(error);
                    }
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    // Функция для отправки данных на сервер
    const sendDataToServer = async (name, value, id) => {
        try {
            const stateSend = {
                action: 'savecheckbox',
                name: name,
                value: value,
                id: id // Передаём идентификатор элемента
            };
    
            const response = await fetch('https://spaceshipbridge.com/ud/api.php', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(stateSend)
            });
            
            const json = await response.json();
            console.log(json);

            return json; // Возвращаем результат
        } catch (error) {
            console.error(error);
            throw error; // Пробрасываем ошибку для обработки в вызывающей функции
        }
    };

    // Функция для изменения активности элемента (on/off)
    const changeActive = async (event) => {
        const uuid = event.target.closest('button').dataset.uuid; // Получаем UUID элемента
        const currentActive = parseInt(event.target.closest('button').dataset.active); // Получаем текущее значение active
        const newActive = currentActive === 1 ? 0 : 1; // Определяем новое значение активности

        try {
            // Отправляем новое значение активности на сервер
            await sendDataToServer('active', newActive, uuid);

            // Если запрос успешен, обновляем состояние
            setCloacksData(prevState => prevState.map(cloack =>
                cloack.uuid === uuid ? { ...cloack, active: newActive } : cloack
            ));
        } catch (error) {
            console.error("Ошибка при обновлении активности:", error);
        }
    };

    // Загрузка данных при первом рендере
    useState(() => {
        setCloacksData([]);
        try {
            const searchParams = new URLSearchParams({
                action: 'getcloacks',
                id: localStorage.getItem('user.id')
            });
            fetch(`https://spaceshipbridge.com/ud/api.php?${searchParams.toString()}`)
            .then(response => response.json())
            .then(json => {
                json.data && json.data.forEach(e => setCloacksData(prevState => ([...prevState, e])));
            });
        } catch (error) {
            console.error(error);
        }
    }, []);

    const openPopup = () => dispatch(updateAddCloackPopup(true))
    const closePopup = () => dispatch(updateAddCloackPopup(false))
    const openAddCloackPopup = () => openPopup()

    const [addCloackData,setAddCloackData] = useState({
        name: '',
        url1: '',
        url2: ''
    })
    const changeCloackData = (event) => {
        const value = event.target.value
        const name = event.target.name
        setAddCloackData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }
    const submitAddCloack = event => {
        event.preventDefault()
        try {
            const stateSend = {
                action: 'addcloack',
                user_uuid: localStorage.getItem('user.id'),
                name: addCloackData.name,
                url1: addCloackData.url1,
                url2: addCloackData.url2
            };
            fetch('https://spaceshipbridge.com/ud/api.php', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(stateSend)
            })
            .then(response => response.json())
            .then(json => {
                if (json.status === 200 && json.id > 0 && json.uuid.length > 0) window.location.href = `/cloak/${json.uuid}`
            });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <h1 className="cloacks_h1">
                {t('cloak.header.cloaks')}
                <button
                    className="btn btn-primary"
                    onClick={openAddCloackPopup}
                ><FontAwesomeIcon icon={faPlusSquare} /> {t('cloak.cloaks.add')}</button>
            </h1>

            <table className="table">
                <thead>
                    <tr>
                        <th><FontAwesomeIcon icon={faToggleOn} /></th>
                        <th>{t('cloak.cloaks.name')}</th>
                        <th>{t('cloak.cloaks.geo')}</th>
                        <th>{t('cloak.cloaks.os')}</th>
                        <th>{t('cloak.cloaks.visits')}</th>
                        <th>{t('cloak.cloaks.actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {cloacksData.map((e, i) =>
                        <tr key={`singlecloak${i}`}>
                            <td>
                                <button
                                    data-uuid={e.uuid}
                                    data-active={e.active}
                                    onClick={changeActive}
                                >
                                    {e.active === 1
                                        ? <FontAwesomeIcon icon={faToggleOn} className="green" />
                                        : <FontAwesomeIcon icon={faToggleOff} className="red" />
                                    }
                                </button>
                            </td>
                            <td>
                                <a href={`/cloak/${e.uuid}`}>{e.name}</a>
                                <span className="table_domain_name">{getDomainFromURL(e.url1)} &rarr; {getDomainFromURL(e.url2)}</span>
                            </td>
                            <td>
                                {e.countries.replaceAll(',', ', ')}
                                <strike>{e.countriesstop.replaceAll(',', ', ')}</strike>
                            </td>
                            <td>
                                <div className="table_devices">
                                    {e.windows === 1 && <FontAwesomeIcon icon={faWindows} className="windows" title="Windows" />}
                                    {e.mac === 1 && <FontAwesomeIcon icon={faApple} className="macos" title="OS X" />}
                                    {e.android === 1 && <FontAwesomeIcon icon={faAndroid} className="android" title="Android" />}
                                    {e.ios === 1 && <FontAwesomeIcon icon={faMobileButton} title="iOS" />}
                                </div>
                            </td>
                            <td>{(e.stat.passed  || e.stat.blocked) ? <><span className="green">{e.stat.passed}</span> / <span className="red">{e.stat.blocked}</span> / {e.stat.passed+e.stat.blocked}</> : ''}</td>
                            <td>
                                <div className="table_icon">
                                    <a href={`/cloak/${e.uuid}`} className="btn btn-default">
                                        <FontAwesomeIcon icon={faGear} />
                                    </a>
                                    <button
                                        className="btn btn-default"
                                        onClick={copyCloack}
                                        data-uuid={e.uuid}
                                    >
                                        <FontAwesomeIcon icon={faCopy} />
                                    </button>
                                    <button
                                        className="btn btn-danger"
                                        onClick={removeCloack}
                                        data-uuid={e.uuid}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <div className={[`add_cloack_wrapper`,addCloackPopup ? 'active' : 'notactive'].join(' ')}>
                <form className="add_cloack" onSubmit={submitAddCloack}>
                    <h2>{t('cloak.cloaks.add')}</h2>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={t('cloak.cloaks.cloakname')}
                        name="name"
                        value={addCloackData.name}
                        onChange={changeCloackData}
                        required
                    />
                    <input
                        type="url"
                        className="form-control"
                        placeholder="White URL"
                        name="url1"
                        value={addCloackData.url1}
                        onChange={changeCloackData}
                        required
                    />
                    <input
                        type="url"
                        className="form-control"
                        placeholder="Black URL"
                        name="url2"
                        value={addCloackData.url2}
                        onChange={changeCloackData}
                        required
                    />
                    <button type="submit" className="btn btn-primary"><FontAwesomeIcon icon={faFloppyDisk} /> {t('cloak.cloaks.add')}</button>
                </form>
                <div className="add_cloack_overlay" onClick={closePopup}></div>
            </div>
        </>
    );
};
