import { useState } from "react"

import { t } from 'i18next'

export const LoginPage = () => {

    const [email,setEmail] = useState('')
    const [pass,setPass] = useState('')
    const [errorMessage,setErrorMessage] = useState(false)

    const inputChange = (setter) => (event) => {
        const value = event.target.value
        setter(value)
    }

    const formSubmit = event => {
        event.preventDefault()
        try {
            const stateSend = {
                action: 'loginsite',
                email: email,
                pass: pass
            }
            fetch(`https://spaceshipbridge.com/ud/api.php`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(stateSend)
            })
            .then(response => response.json())
            .then(json => {
                if (json.id) {
                    localStorage.setItem('user.id',json.id)
                    window.location.reload()
                } else {
                    setErrorMessage(true)
                }
            })
        } catch(error) {
            console.error(error)
        }
    }

    const changeLang = e => {
        const currentLang = e.target.dataset.value
        localStorage.setItem('locale',currentLang)
        window.location.reload()
    }

    return (
        <div className="auth_page">
            <form className="auth_page_form" onSubmit={formSubmit}>
                {errorMessage && <div className="auth_page_form_error">Wrong login or password</div>}
                <input
                    className="form-control"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={inputChange(setEmail,'email')}
                    type="email"
                />
                <input
                    className="form-control"
                    name="pass"
                    placeholder={t('cloak.login.password')}
                    value={pass}
                    onChange={inputChange(setPass,'pass')}
                    type="password"
                />
                <button type="submit" className="btn btn-primary">{t('cloak.login.auth')}</button>
            </form>
            <div className="auth_page_langs">
                <button
                    className={[`btn`,localStorage.getItem('locale') === 'en' ? 'btn-primary' : 'btn-default'].join(' ')}
                    data-value="en"
                    onClick={changeLang}
                >En</button>
                <button
                    className={[`btn`,localStorage.getItem('locale') === 'ru' ? 'btn-primary' : 'btn-default'].join(' ')}
                    data-value="ru"
                    onClick={changeLang}
                >Ru</button>
            </div>
        </div>
    )
}